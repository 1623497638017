// !Note  This file will be deleted for MVP2
const questionsToIgnoreMVP1 = [
  'split-ends',
  'porosity',
  'length',
  'texture',
  'color-treatments-location',
  'color',
  'color-treatments-type',
  'color-treatments-cadence',
  'other-treatments-cadence',
  'other-treatments',
  'styles-cadence',
  'styles',
  'stress-intensity',
];

export default questionsToIgnoreMVP1;
