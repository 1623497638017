const packingDisplayedItems = {
  left: {
    products: [
      'shampoo',
      'conditioner',
      'hair_mask',
      'scalp_mask',
      'scalp_serum',
      'leavein',
      'dry_shampoo',
      'leavein_conditioner',
      'curl_cream',
      'styling_gel',
      'supplement_booster',
      'supplement_core',
      'cleanser',
      'moisturizer',
      'serum',
      'cleanser_mini',
      'moisturizer_mini',
      'serum_mini',
      'shimmer_hair_oil',
      'brush',
      'candle',
      'hair_towel_wrap',
      'trial_conditioner',
      'trial_shampoo',
    ],
  },
  right: {
    products: [
      'box',
      'unit_carton',
      'module',
      'tray',
      'pad',
      'pump',
      'dropper',
      'stickers',
      'sealing_sticker',
      'postcard',
      'digital_gift',
      'gwp',
      'toiletry_bag',
      'eye_mask',
      'scrunchie',
      'travel_pouches',
      'loyalty_gift',
      'samples',
    ],
  },
};

export default packingDisplayedItems;
