import get from 'lodash/fp/get';

import { createSelector } from 'reselect';
import type { RootState } from 'store';
import type { OmsSelector } from 'types/common';

type SettingsSlice = RootState['settings'];

const getSettingsState: OmsSelector<SettingsSlice> = state => state.settings;

export const getSelectedLane: OmsSelector<SettingsSlice['selectedLane']> = createSelector(
  getSettingsState,
  get('selectedLane')
);

export const getViewMode: OmsSelector<SettingsSlice['viewMode']> = createSelector(
  getSettingsState,
  get('viewMode')
);

export const getProductionCellPubkey: OmsSelector<SettingsSlice['productionCellPubkey']> =
  createSelector(getSettingsState, get('productionCellPubkey'));
export const getIsAdminView: OmsSelector<boolean> = createSelector(
  getSettingsState,
  ({ viewMode }) => viewMode === 'admin'
);

export const getScannerSettings: OmsSelector<SettingsSlice['scannerSettings']> = createSelector(
  getSettingsState,
  get('scannerSettings')
);

export const getSelectedProductionStation: OmsSelector<SettingsSlice['selectedProductionStation']> =
  createSelector(getSettingsState, get('selectedProductionStation'));

export const getSelectedBufferSpace: OmsSelector<SettingsSlice['selectedBufferSpace']> =
  createSelector(getSettingsState, get('selectedBufferSpace'));
