import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import isNull from 'lodash/fp/isNull';

import initApiClient from 'services/ApiClient';

import logSentryError from 'utils/sentry';

import { initialState } from './initialState';
import type { FeatureFlags } from './types';

export const fetchFeatureFlags = createAsyncThunk<FeatureFlags>(
  'featureFlag/fetchFeatureFlag',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const response = await APIClient.get('/flags');
      const fetchedFeatureFlags = await response.json();
      return fetchedFeatureFlags;
    } catch (error) {
      logSentryError('[Flipt]', error, { logLevel: 'info' });
      throw error;
    }
  }
);

const featureFlagSlice = createSlice({
  name: 'featureFlag',
  initialState,
  reducers: {
    dispatchFeatureFlag(draftState, action: PayloadAction<FeatureFlags>) {
      draftState.flags = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // fetchFeatureFlags
      .addCase(fetchFeatureFlags.pending, draftState => {
        draftState.status = 'pending';
      })
      .addCase(fetchFeatureFlags.fulfilled, (draftState, action) => {
        const fetchedFeatureFlags = action.payload;

        draftState.status = 'fulfilled';
        if (!isNull(fetchedFeatureFlags)) {
          draftState.flags = fetchedFeatureFlags;
        }
      })
      .addCase(fetchFeatureFlags.rejected, draftState => {
        draftState.status = 'rejected';
      });
  },
});

const { reducer, actions } = featureFlagSlice;

export const { dispatchFeatureFlag } = actions;

export default reducer;
